/* eslint jsx-a11y/html-has-lang: 0 */
import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { BrinkContext } from "../context/BrinkContext"
import { boxShadow } from "../../constants"
import Button from "../ui/Button"
import Item from "./Item"
import Totals from "./Totals"
import PaymentLogos from "../checkout/PaymentLogos"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 50rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: white;
  background: ${(p) => p.theme.colors.background};
  color: ${(p) => p.theme.colors.black};
  box-shadow: ${boxShadow};
  transform: translate(${(p) => (p.openCart ? "0, 0" : "100%, 0")});
  transition: transform 0.2s;
  z-index: 10003;
`

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  div {
    display: flex;
    align-items: center;

    svg {
      width: 2rem;
      height: 2.4rem;
      margin: 0 1rem 0.4rem 0;
    }

    .st0 {
      fill: transparent;
    }
  }
`

const EmptyCart = styled.p`
  text-align: center;
  margin-top: 3rem;
`

const Heading = styled.p`
  font-size: 1.8rem;
  display: flex;
  margin-top: 1.6rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.2rem;
`

const CloseButton = styled.div`
  font-size: 2rem;
  cursor: pointer;
`

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 2.5rem 0 3rem;
  margin-bottom: -0.1rem;
`

const Footer = styled.div`
  padding: 0 2.5rem 4rem;
`

const CheckoutButton = styled(Button)`
  width: 100%;
`

const ContinueShopping = styled.button`
  background: none;
  border: 0;
  margin: 0 auto 3rem;
  display: block;
  cursor: pointer;
  position: relative;
  padding: 0;
  letter-spacing: 0.2rem;
  font-size: 1.3rem;

  &:after {
    content: "";
    position: absolute;
    height: 0.1rem;
    left: 0;
    right: 0;
    bottom: -0.3rem;
    background-color: ${(p) => p.theme.colors.black};
    transition: background-color 0.2s;
  }

  &:hover {
    &:after {
      background-color: transparent;
    }
  }
`

const Cart = ({ closeCart, openCart }) => {
  const { t } = useTranslation("translations")
  const { cart, isCartClosed } = useContext(BrinkContext)
  const cartQuantity = !isCartClosed()
    ? cart.cartItems.reduce((prev, cur) => {
        if (cur.type === "shippingOption") return prev
        return prev + cur.quantity
      }, 0)
    : 0

  return (
    <Container openCart={openCart}>
      {openCart && (
        <Helmet>
          <html className="disableScroll" />
          <body className="disableScroll" />
        </Helmet>
      )}
      <Header>
        <CloseButton onClick={() => closeCart()}>
          <i className="fal fa-times"></i>
        </CloseButton>
        <div>
          <Heading>{t("Your cart")}</Heading>
        </div>
        <div></div>
      </Header>
      <Body>
        {cartQuantity !== 0 && !isCartClosed() ? (
          <>
            <Items>
              {cart.cartItems.map((item) => (
                <Item item={item} key={item.id} showControls={true} />
              ))}
            </Items>
            <Footer>
              <Totals hideShipping={true} />
              <Link to="/checkout/">
                <CheckoutButton onClick={() => closeCart()}>
                  {t("Proceed to checkout")}
                </CheckoutButton>
              </Link>
              <PaymentLogos />
              <ContinueShopping onClick={() => closeCart()}>
                {t("Continue shopping")}
              </ContinueShopping>
            </Footer>
          </>
        ) : (
          <EmptyCart>{t("You have no items in your cart.")}</EmptyCart>
        )}
      </Body>
    </Container>
  )
}

export default Cart
