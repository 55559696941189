import React, { useState, useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import i18next from "i18next"
import ISO6391 from "iso-639-1"
import { getLocalizedCountryName } from "../../helpers/countries"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_LARGE } from "../../constants"
import OutsideClickHandler from "react-outside-click-handler"
import countries from "i18n-iso-countries"
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))

const Container = styled.div`
  display: ${(p) => (p.isMobile ? "flex" : "none")};
  cursor: pointer;

  ${MEDIA_MIN_LARGE} {
    display: ${(p) => (p.isMobile ? "none" : "flex")};
    align-items: center;
    flex-shrink: 0;
  }

  ${(p) =>
    p.isMobile &&
    css`
      > div {
        display: flex;
        width: 100%;
      }
    `}

  i {
    font-size: 1.4rem;
    margin-left: 0.4rem;
    padding-top: 0.4rem;
  }
`

const SelectorWrapper = styled.div`
  position: ${(p) => (p.isMobile ? "relative" : "absolute")};
  top: ${(p) => (p.isMobile ? "0" : "5rem")};
  background-color: ${(p) =>
    p.isMobile ? p.theme.colors.primary : "rgba(255, 255, 255, 0.9)"};
  padding: ${(p) => (p.isMobile ? "2rem 3rem 1rem" : "2rem 2rem 1rem")};
  width: ${(p) => (p.isMobile ? "100%" : "35rem")};
  right: 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.show ? "1" : "0")};
  z-index: ${(p) => (p.show ? "10000" : "-1")};
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  box-shadow: ${(p) =>
    p.isMobile ? "none" : "0 1px 4px 0 rgba(0, 0, 0, 0.1)"};
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(p) => p.theme.colors.lightGrey};

  ${MEDIA_MIN_LARGE} {
    flex-direction: column;
  }
`

const Selected = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: 1.2rem;
  text-transform: capitalize;
`

const Label = styled.div`
  color: ${(p) => p.theme.colors.black};
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding-right: 1rem;
  text-transform: uppercase;

  ${MEDIA_MIN_LARGE} {
    font-size: 1.2rem;
  }
`

const Option = styled.option`
  color: ${(p) => p.theme.colors.black};
`

const Select = styled.select`
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  height: 4rem;
  color: ${(p) => p.theme.colors.black};
  border: none;
  letter-spacing: 0.2rem;
  text-align: left;
  padding: 0;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }
`

const CountrySelector = ({ isMobile, showAsPopup, inverted }) => {
  const { t } = useTranslation("translations")
  const {
    languageCode,
    supportedLanguages,
    stores,
    currentStore,
    setCurrentStore
  } = useContext(BrinkContext)

  const [sortedStoresWithInitial, setSortedStoresWithInitial] = useState()
  const [showCountrySelector, setShowCountrySelector] = useState(false)

  const getStoreFromCountryCode = (countryCode) =>
    stores.find((store) => countryCode === store.countryCode)
  const sortedStores = [...stores].sort((a, b) =>
    countries
      .getName(a.countryCode, "en")
      .localeCompare(countries.getName(b.countryCode, "en"))
  )

  useEffect(() => {
    sortedStores.unshift(
      sortedStores.splice(
        sortedStores.findIndex(
          (store) => store.countryCode === currentStore.countryCode
        ),
        1
      )[0]
    )
    setSortedStoresWithInitial(sortedStores)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore])

  if (!currentStore) return null
  return (
    <Container isMobile={isMobile} showAsPopup={showAsPopup}>
      {!isMobile && (
        <Selected onClick={() => setShowCountrySelector(!showCountrySelector)}>
          {getLocalizedCountryName(currentStore.countryCode, languageCode, {
            select: "official"
          })}{" "}
          | {currentStore.currencyUnit} | {languageCode}
          <i className="fal fa-angle-down"></i>
        </Selected>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowCountrySelector(false)
        }}
      >
        <SelectorWrapper
          isMobile={isMobile}
          show={showCountrySelector || isMobile}
        >
          <div>
            <Label>{t("Country")}</Label>
            <Select
              inverted={inverted}
              value={currentStore.countryCode}
              onChange={(e) =>
                setCurrentStore(getStoreFromCountryCode(e.target.value))
              }
            >
              {sortedStoresWithInitial &&
                sortedStoresWithInitial.map((store) => (
                  <Option key={store.countryCode} value={store.countryCode}>
                    {countries.getName(store.countryCode, "en")} |{" "}
                    {store.currencyUnit}
                  </Option>
                ))}
            </Select>
          </div>
          <div style={{ display: "none" }}>
            <Label>{t("Language")}</Label>
            <Select
              value={languageCode}
              onChange={(e) => {
                i18next.changeLanguage(e.target.value)
              }}
            >
              {supportedLanguages.map((language) => (
                <Option key={language} value={language}>
                  {ISO6391.getNativeName(language)}
                </Option>
              ))}
            </Select>
          </div>
        </SelectorWrapper>
      </OutsideClickHandler>
    </Container>
  )
}

export default CountrySelector
