/* eslint jsx-a11y/html-has-lang: 0 */
import React, { useState, useContext } from "react"
import styled, { css, ThemeContext } from "styled-components"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  containerLargeMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MAX_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import { usePage } from "../context/PageContext"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"
import CountrySelector from "./CountrySelector"

const Container = styled.div`
  position: fixed;
  top: 0;
  transition: all 0.3s;
  transform: translate(${(p) => (p.open ? "0, 0" : "-100%, 0")});
  width: 100%;
  z-index: 1000;

  ${MEDIA_MIN_LARGE} {
    width: 100%;
    position: fixed;
    max-width: none;
    position: relative;
    flex-wrap: nowrap;
    height: 100%;
    background: none;
    transform: none;
    overflow-y: initial;
  }
`

const Wrapper = styled.div`
  background: ${(p) => p.theme.colors.white};
  height: 100vh;

  ${MEDIA_MIN_LARGE} {
    background: none;
    height: 100%;
  }
`

const MenuClose = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1.5rem;
  left: 1.5rem;
  color: ${(p) => p.theme.colors.black};
  font-size: 2rem;

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const Menu = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 5rem 0 2rem;
  margin: 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0 6rem;
    height: 100%;
    flex-wrap: nowrap;
  }
`

const MenuItem = styled.li`
  position: relative;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
  transition: border-bottom 0.2s;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

  &:first-of-type {
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  }

  ${MEDIA_MIN_LARGE} {
    width: auto;
    margin: 0 3rem;
    padding: 2rem 0;
    font-size: 1.5rem;
    text-align: center;
    max-width: 24rem;
    border-bottom: none;

    &:first-of-type {
      border-top: none;
    }

    ${(p) =>
      p.hasChildren &&
      css`
        &:hover {
          > div {
            display: flex;
          }
        }
      `}
  }
`

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  padding: 2.8rem 3rem 2.4rem;
  color: ${(p) => p.theme.colors.black};
  letter-spacing: 0.2rem;

  ${MEDIA_MAX_MEDIUM} {
    i {
      position: absolute;
      right: 3rem;
      transition: transform 0.2s;
      transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
      font-size: 2.4rem;
    }
  }

  ${MEDIA_MIN_LARGE} {
    color: ${(p) =>
      p.sticky || p.inverted || p.openMenu
        ? p.theme.colors.black
        : p.theme.colors.white};
    padding: 0.2rem 0 0;
    height: 100%;

    i {
      margin-left: 1rem;
    }
  }
`

const SubMenu = styled.div`
  background: ${(p) => p.theme.colors.white};

  ${MEDIA_MIN_LARGE} {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    margin: 2rem auto;
    border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
    padding: 5rem 3rem;
    width: 100%;
    z-index: 1000;

    > div {
      max-width: ${containerLargeMaxWidth};
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }

  ul {
    list-style: none;
    padding: 0 5rem;
    max-height: ${(p) => (p.open ? "35rem" : "0")};
    overflow: hidden;
    min-width: 30rem;
    flex-wrap: wrap;
    transition: max-height 0.2s;

    ${MEDIA_MIN_LARGE} {
      flex-direction: column;
      max-height: none;
    }
  }
`

const SubMenuItem = styled.li`
  line-height: 5rem;
  font-size: 1.3rem;
  width: 100%;
  letter-spacing: 0.2rem;
  text-align: left;

  &:last-child {
    padding-bottom: 2rem;
  }

  ${MEDIA_MIN_LARGE} {
    width: auto;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

const ContactLink = styled(Link)`
  text-transform: uppercase;
  font-size: 1.3rem;
  color: ${(p) => p.theme.colors.black};
  position: absolute;
  top: 3rem;
  left: 3rem;
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const EntryLinks = styled.div`
  display: none;
  padding: 1rem;

  ${MEDIA_MIN_LARGE} {
    display: flex;
  }
`

const EntryLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4rem;
`

const SubTitle = styled.p`
  text-transform: initial;
  color: #999;
`

const Image = styled(GatsbyImage)`
  max-width: 40rem;
  margin-bottom: 2rem;
`

const HeaderMenu = ({ inverted, openMenu, closeMenu, sticky, setOpenMenu }) => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)
  const { languageCode } = useContext(BrinkContext)
  const { headerMenu, buildLanguage } = usePage()
  const [focusedMenu, setFocusedMenu] = useState(null)
  const { menuItems } = headerMenu || {}

  return (
    <Container open={openMenu}>
      <Wrapper>
        {openMenu && (
          <Helmet>
            <html className="openMenu" />
            <body className="openMenu" />
          </Helmet>
        )}
        <ContactLink
          inverted={inverted}
          sticky={sticky}
          to="/customer-service/"
        >
          {t("Support")}
        </ContactLink>
        <MenuClose onClick={() => closeMenu()}>
          <i className="fal fa-times"></i>
        </MenuClose>
        <Menu>
          {menuItems?.map((menuItem) => {
            const { _id, title, slug, childMenuItems, childMenuEntrys } =
              menuItem
            const hasChildItems = childMenuItems?.length > 0
            const localizedSlug = slug[buildLanguage] || slug.en

            return (
              <MenuItem
                key={_id}
                hasChildren={hasChildItems}
                onMouseOver={() => {
                  if (hasChildItems) {
                    setOpenMenu(true)
                  }
                }}
                onMouseLeave={() => {
                  setOpenMenu(false)
                }}
              >
                <MenuLink
                  open={localizedSlug === focusedMenu}
                  inverted={inverted}
                  sticky={sticky}
                  openMenu={openMenu}
                  to={`/${localizedSlug}/`}
                  onClick={(e) => {
                    if (hasChildItems) {
                      e.preventDefault()
                      setFocusedMenu(
                        localizedSlug !== focusedMenu ? localizedSlug : null
                      )
                    } else {
                      closeMenu()
                    }
                  }}
                >
                  {title[languageCode] || title.en}
                  {hasChildItems && <i className="fal fa-angle-down"></i>}
                </MenuLink>
                {hasChildItems && (
                  <SubMenu open={localizedSlug === focusedMenu}>
                    <div>
                      <ul>
                        <SubMenuItem>
                          <Link
                            underline
                            inverted
                            color={colors.black}
                            to={`/${localizedSlug}/`}
                            onClick={() => closeMenu()}
                          >
                            {t("View all")}
                          </Link>
                        </SubMenuItem>
                        {childMenuItems.map((subMenuItem) => (
                          <SubMenuItem key={subMenuItem._id}>
                            <Link
                              underline
                              inverted
                              color={colors.black}
                              to={`/${
                                subMenuItem.slug[buildLanguage] ||
                                subMenuItem.slug.en
                              }/`}
                              onClick={() => closeMenu()}
                            >
                              {subMenuItem.title[languageCode] ||
                                subMenuItem.title.en}
                            </Link>
                          </SubMenuItem>
                        ))}
                      </ul>
                      {childMenuEntrys?.length > 0 && (
                        <EntryLinks>
                          {childMenuEntrys.map((entryLink) => (
                            <EntryLink
                              key={entryLink._id}
                              color={colors.black}
                              to={`/${
                                entryLink.slug[buildLanguage] ||
                                entryLink.slug.en
                              }/`}
                            >
                              <Image
                                image={getImage(entryLink.image.asset)}
                                alt={
                                  entryLink.title[languageCode] ||
                                  entryLink.title.en
                                }
                              />
                              {entryLink.title[languageCode] ||
                                entryLink.title.en}
                              <SubTitle>
                                {entryLink.subTitle[languageCode] ||
                                  entryLink.subTitle.en}
                              </SubTitle>
                            </EntryLink>
                          ))}
                        </EntryLinks>
                      )}
                    </div>
                  </SubMenu>
                )}
              </MenuItem>
            )
          })}
        </Menu>
        <CountrySelector isMobile inverted={sticky} />
      </Wrapper>
    </Container>
  )
}

export default HeaderMenu
