import React, { useContext } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_LARGE } from "../../constants"

const Container = styled.div`
  height 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  .slick-slider {
    width: 100%;
  }
`

const Usp = styled.div`
  height 5rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.3rem;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    font-size: 1.4rem;
  }

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;
    color: ${(p) =>
      p.inverted ? p.theme.colors.primary : p.theme.colors.primary};
  }
`

const TopBarUsp = ({ inverted, sanityUsp }) => {
  const { languageCode } = useContext(BrinkContext)

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    draggable: false,
    slidesToShow: sanityUsp?.uspEntries?.length || 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Container>
      <Slider {...settings}>
        {sanityUsp.uspEntries.map((entry) => (
          <Usp key={entry._key} inverted={inverted}>
            <i className="fal fa-check"></i>
            {entry[languageCode] || entry.en}
          </Usp>
        ))}
      </Slider>
    </Container>
  )
}

export default TopBarUsp
