import React, { useState, useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import loadable from "@loadable/component"
import {
  containerLargeMaxWidth,
  MEDIA_MIN_LARGE,
  MEDIA_MIN_X_LARGE
} from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import { usePage } from "../context/PageContext"
import Link from "../Link"
import MenuButton from "./MenuButton"
import Menu from "./Menu"
import Tools from "./Tools"
import Logo from "../../images/rondure-logo.svg"
import Cart from "../cart/Cart"
import Backdrop from "../ui/Backdrop"

const NewsletterPopup = loadable(() => import("../widgets/NewsletterPopup"))

const Wrapper = styled.div`
  width: 100%;
  z-index: 10;
  position: ${(p) =>
    p.sticky ? "fixed" : p.absoluteHeader ? "absolute" : "relative"};
  top: ${(p) => (p.absoluteHeader && !p.sticky && p.hasUsp ? "5rem" : "0")};
`

const Container = styled.div`
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background: ${(p) => (p.sticky ? p.theme.colors.white : "transparent")};

  ${MEDIA_MIN_LARGE} {
    background: ${(p) =>
      p.sticky || p.openMenu ? p.theme.colors.white : "transparent"};
  }
`

const Content = styled.div`
  max-width: ${containerLargeMaxWidth};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 10rem;
  transition: height 0.2s;
  margin: 0 auto;
  border-bottom: 0.1rem solid
    ${(p) =>
      p.inverted && !p.noHeaderBorder
        ? p.theme.colors.lightBorder
        : "transparent"};

  ${MEDIA_MIN_LARGE} {
    height: auto;
  }
`

const HomeLink = styled(Link)`
  display: inline-block;
  margin: 0 auto;
  text-align: center;

  ${MEDIA_MIN_LARGE} {
    display: inline-block;
    padding-top: 3rem;
  }

  ${MEDIA_MIN_X_LARGE} {
  }

  svg {
    transition: width 0.2s;
    width: ${(p) => (p.sticky ? "12rem" : "12rem")};
    padding-top: 0.5em;
    fill: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};

    ${MEDIA_MIN_X_LARGE} {
      padding-top: 0.4rem;
    }
  }
`

const Header = ({
  inverted,
  hideCountrySelector,
  absoluteHeader,
  noHeaderBorder
}) => {
  const { isCartOpen, setIsCartOpen } = useContext(BrinkContext)
  const { sanityDiscountBanner, sanityUsp } = usePage()
  const { enabled: discountBannerEnabled, sticky: discountBannerSticky } =
    sanityDiscountBanner || {}
  const [openMenu, setOpenMenu] = useState(false)
  const [sticky, setSticky] = useState(false)

  const headerRef = useRef()

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  const close = () => {
    setIsCartOpen(false)
    setOpenMenu(false)
  }

  const stick = () => {
    const currentPos = window.pageYOffset
    setSticky(currentPos > 50)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(stick, [])

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", stick)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", stick)
    }
  })

  return (
    <div className="top">
      <Wrapper
        ref={headerRef}
        absoluteHeader={absoluteHeader}
        sticky={sticky}
        hasDiscountBanner={discountBannerEnabled && !discountBannerSticky}
        hasUsp={sanityUsp?.uspEntries?.length}
      >
        <NewsletterPopup />
        <Container
          sticky={sticky}
          discountBannerSticky={discountBannerEnabled && discountBannerSticky}
          openMenu={openMenu}
        >
          <Content
            inverted={inverted}
            sticky={sticky}
            noHeaderBorder={noHeaderBorder}
          >
            <MenuButton
              sticky={sticky}
              inverted={inverted}
              toggleMenu={toggleMenu}
              openMenu={openMenu}
            />
            <HomeLink
              title="Go to start page"
              inverted={inverted}
              sticky={sticky}
              to="/"
            >
              <Logo alt="Brink logo" />
            </HomeLink>
            <Menu
              closeMenu={close}
              openMenu={openMenu}
              inverted={inverted}
              sticky={sticky}
              setOpenMenu={setOpenMenu}
            />
            <Tools
              inverted={inverted}
              sticky={sticky}
              toggleCart={toggleCart}
              hasDiscountBanner={discountBannerEnabled}
            />
          </Content>
        </Container>
      </Wrapper>
      <Backdrop active={isCartOpen} handleOnClick={() => close()} />
      <Cart closeCart={close} openCart={isCartOpen} />
    </div>
  )
}

export default Header
