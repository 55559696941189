import React from "react"
import styled, { ThemeProvider } from "styled-components"
import loadable from "@loadable/component"
import { PageProvider } from "./context/PageContext"
import { theme } from "../theme"
import Head from "./Head"
import Header from "./header/Header"
import TopBar from "./header/TopBar"

const CookieConsent = loadable(() => import("./cookieConsent/CookieConsent"))
const Footer = loadable(() => import("./footer/Footer"))

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${(p) => (p.stickyDiscountBanner ? "6rem" : "0")};
`
const Body = styled.div`
  flex: 1 0 auto;
`

const Layout = ({
  meta,
  invertedHeader,
  hideCountrySelector,
  children,
  pageContext,
  intSlug,
  slug,
  absoluteHeader,
  noHeaderBorder
}) => {
  const { footerMenu, sanityDiscountBanner } = pageContext
  const { enabled, sticky } = sanityDiscountBanner || {}

  return (
    <ThemeProvider theme={theme()}>
      <PageProvider pageContext={pageContext}>
        <Wrapper stickyDiscountBanner={enabled && sticky}>
          <Head meta={meta} intSlug={intSlug} slug={slug} />
          <TopBar
            inverted={invertedHeader}
            hideCountrySelector={hideCountrySelector}
            absoluteHeader={absoluteHeader}
          />
          <Header
            inverted={invertedHeader}
            hideCountrySelector={hideCountrySelector}
            absoluteHeader={absoluteHeader}
            noHeaderBorder={noHeaderBorder}
          />
          <CookieConsent />
          <Body>{children}</Body>
          <Footer footerMenu={footerMenu} />
        </Wrapper>
      </PageProvider>
    </ThemeProvider>
  )
}

export default Layout
