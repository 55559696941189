import React from "react"
import styled from "styled-components"
import { containerLargeMaxWidth } from "../../constants"
import { usePage } from "../context/PageContext"
import TopBarUsp from "../widgets/TopBarUsp"

const Container = styled.div`
  width: 100%;
  text-align: center;
  color: white;
  z-index: 10;
  position: ${(p) => (p.absoluteHeader ? "absolute" : "relative")};
  font-size: 1.2rem;
  color: ${(p) => (p.inverted ? p.theme.colors.black : p.theme.colors.white)};
`

const Grid = styled.div`
  max-width: ${containerLargeMaxWidth};
  margin: 0 auto;
  position: relative;
  height: 5rem;
  border-bottom: 0.1rem solid
    ${(p) =>
      p.inverted ? p.theme.colors.lightBorder : "rgba(255, 255, 255, 0.2)"};
`

const TopBar = ({ sticky, inverted, absoluteHeader }) => {
  const { sanityUsp } = usePage()

  if (!sanityUsp?.uspEntries?.length > 0) return null

  return (
    <Container
      inverted={inverted}
      sticky={sticky}
      absoluteHeader={absoluteHeader}
    >
      <Grid inverted={inverted}>
        <TopBarUsp inverted={inverted} sanityUsp={sanityUsp} />
      </Grid>
    </Container>
  )
}

export default TopBar
