import React, { useState, useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_LARGE, MEDIA_MIN_X_LARGE } from "../../constants"
import Search from "./Search"
import BagIcon from "../../icons/bag-icon.svg"
import CountrySelector from "./CountrySelector"

const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 2.5rem;
  padding-top: 0.5rem;
  color: ${(p) =>
    p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};

  ${MEDIA_MIN_LARGE} {
    justify-content: flex-end;
    padding-top: 0.2rem;
    bottom: 1.8rem;
    z-index: 1001;
  }

  ${MEDIA_MIN_X_LARGE} {
    right: 0;
  }
`

const SearchIcon = styled.div`
  margin-left: 2.4rem;
  cursor: pointer;

  i {
    font-size: 1.8rem;
  }
`

const CartIcon = styled.div`
  margin-left: 2.4rem;
  cursor: pointer;
  position: relative;

  svg {
    width: 1.8rem;
    margin-bottom: 0.2rem;
    fill: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};
  }

  .st0 {
    fill: transparent;
  }
`

const CartQuantity = styled.span`
  position: absolute;
  color: ${(p) =>
    p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 1.2rem;
  padding: 0 0 0 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.1rem;
  right: -2rem;
  font-size: 1.2rem;
`

const Tools = ({ toggleCart, inverted, sticky, hasDiscountBanner }) => {
  const [openSearch, setOpenSearch] = useState(false)
  const { cart, isCartClosed } = useContext(BrinkContext)
  const cartQuantity = !isCartClosed()
    ? cart.cartItems.reduce((prev, cur) => {
        if (cur.type === "shippingOption") return prev
        return prev + cur.quantity
      }, 0)
    : 0

  return (
    <Container inverted={inverted} sticky={sticky}>
      <CountrySelector inverted={sticky} />
      <SearchIcon onClick={() => setOpenSearch(!openSearch)}>
        <i className="fal fa-search"></i>
      </SearchIcon>
      <CartIcon
        inverted={inverted}
        sticky={sticky}
        onClick={() => toggleCart()}
      >
        <BagIcon />
        {cartQuantity > 0 && (
          <CartQuantity inverted={inverted} sticky={sticky}>
            {cartQuantity}
          </CartQuantity>
        )}
      </CartIcon>
      <Search
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        sticky={sticky}
        hasDiscountBanner={hasDiscountBanner}
      />
    </Container>
  )
}

export default Tools
