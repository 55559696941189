import React, { useContext, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import { v4 as uuidv4 } from "uuid"
import Input from "../ui/Input"
import Button from "../ui/Button"

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  height: 9rem;
  padding: 0 2rem;
  top: ${(p) =>
    p.hasDiscountBanner
      ? p.sticky
        ? "12.5rem"
        : "17.3rem"
      : p.sticky
      ? "7rem"
      : "12.5rem"};
  right: 0;
  left: 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.openSearch ? "1" : "0")};
  z-index: ${(p) => (p.openSearch ? "10003" : "-1")};
  pointer-events: ${(p) => (p.openSearch ? "all" : "none")};

  label {
    position: absolute;
    height: 0.1rem;
    width: 0.1rem;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    border: 0;
    overflow: hidden;
  }

  ${MEDIA_MIN_MEDIUM} {
    position: fixed;
    width: 100%;
    max-width: ${containerSmallMaxWidth};
    right: 0;
    left: 0;
    margin: 0 auto;
  }
`

const SearchInput = styled(Input)`
  input {
    background: transparent;
    border: 0.1rem solid ${(p) => p.theme.colors.mediumGrey};
    border-right: none;
    color: ${(p) => p.theme.colors.black};
    text-transform: initial;

    &::placeholder {
      color: ${(p) => p.theme.colors.black};
    }
  }
`

const SearchButton = styled(Button)`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.mediumGrey};
  border-left: none;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
  }
`

const Search = ({ openSearch, setOpenSearch, sticky, hasDiscountBanner }) => {
  const { t } = useTranslation("translations")
  const { setSearchQuery, setIsLoading, setSearchId } = useContext(BrinkContext)
  const [searchQuery, _setSearchQuery] = useState()

  const updateQuery = (c) => {
    _setSearchQuery(c.target.value)
  }

  const setQuery = () => {
    if (!searchQuery) return false
    setSearchQuery(searchQuery)
    setIsLoading(true)
    setOpenSearch(false)
    setSearchId(uuidv4())
    navigate("/search/")
  }

  return (
    <Container
      openSearch={openSearch}
      sticky={sticky}
      hasDiscountBanner={hasDiscountBanner}
    >
      {openSearch && (
        <>
          <SearchInput
            label={t("Search products")}
            id="searchInput"
            name="searchInput"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            placeholder={`${t("Search")}...`}
            onChange={(c) => updateQuery(c)}
            onKeyDown={(e) => e.key === "Enter" && setQuery()}
          />
          <SearchButton
            aria-label={t("Search products")}
            onClick={() => setQuery()}
          >
            <i className="fal fa-search"></i>
          </SearchButton>
          
        </>
      )}
    </Container>
  )
}

export default Search
